import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Warmup: Barbell Good Mornings 4×5 & Seated Box Jumps 4×5`}</p>
    <p>{`then,`}</p>
    <p>{`Snatch Grip Deadlifts 5×2@ 100-110% Snatch Max `}<em parentName="p">{`(jump shrug at top of
dl)`}</em></p>
    <p>{`then,`}</p>
    <p>{`3 Rounds for time of:`}</p>
    <p>{`500M Row`}</p>
    <p>{`15-Deadlifts (225/155)(RX+ 275/185)`}</p>
    <p>{`400M Run`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      